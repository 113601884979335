.notification {
  position: fixed;
  top:12%;
  left: 40%;
  background-color: #333;
  color: #fff;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 0;
  transform: translateY(100%);
}

.notification.show {
  opacity: 1;
  transform: translateY(0);
  box-shadow: 3px 4px 4px 1px #b2aeae;
}

.notification-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 12px;
  font-size: 13px;
}

.notification-content button {
  background: none;
  border: none;
  color: inherit;
  font-size: 16px;
  cursor: pointer;
}

.notifyHead {
  display: flex;
  align-items: center;
  color: yellow;
  border-bottom: 1px solid;
  padding: 5px 0px;
}

.notifyIcon {
  font-size: 18px !important;
  color: yellow;
  margin-right: 4px;
}
